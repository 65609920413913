import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IError } from '@suvo-bi-core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditComponent } from '../../../../../shared/components/edit/edit.component';
import { IShip } from '../../interface/ship.interface';
import { ShipsService } from '../../service/ships.service';

@Component({
  selector: 'app-ship-edit-screen',
  templateUrl: './ship-edit-screen.component.html',
  styleUrl: './ship-edit-screen.component.scss',
})
export class ShipEditScreenComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  readonly formGroup = new FormGroup({
    oldShipId: new FormControl<number>(undefined),
    name: new FormControl<string>(undefined),
    imoNumber: new FormControl<number>(undefined),
    mmsi: new FormControl<number>(undefined),
    flag: new FormControl<string>(undefined),
    companyName: new FormControl<string>(undefined),
    startDate: new FormControl<string>(undefined),
    endDate: new FormControl<string>(undefined),
  });

  @ViewChild('editComponent') editComponent: EditComponent;

  ship?: IShip;
  serverErrors: Array<IError>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly ships: ShipsService,
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(({ data }: { data?: IShip }) => {
      if ((this.ship = data)) {
        this.formGroup.setValue({
          oldShipId: data.oldShipId,
          name: data.name,
          imoNumber: data.imoNumber,
          mmsi: data.mmsi,
          flag: data.flag,
          companyName: data.companyName,
          startDate: this.formatDate(data.startDate),
          endDate: this.formatDate(data.endDate),
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSave($event: IShip) {
    this.serverErrors = null;

    let sd = new Date($event.startDate);
    let st = sd.getTime();

    if (isNaN(st)) {
      $event.startDate = null;
    }

    let ed = new Date($event.endDate);
    let et = ed.getTime();

    if (isNaN(et)) {
      $event.endDate = null;
    }

    try {
      if (this.ship?._id) {
        await this.ships.patchOne('', this.ship._id, $event);
        this.editComponent.markAsUpdated();
      } else {
        const { _id } = await this.ships.postOne('', $event);
        await this.router.navigate(['..', _id], { relativeTo: this.route });
      }
    } catch (e) {
      this.serverErrors = [e.error];
      this.editComponent.enable();
    }
  }

  formatDate(date: Date | string | null): string {
    if (date === null) return '';
    date = new Date(date);
    const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
}
