<app-header />

<mat-card class="container">
  <mat-card-header>
    <mat-card-title>Ship Details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-edit
      [formGroup]="formGroup"
      permission="ship.edit"
      (save)="onSave($event)"
      [errors]="serverErrors"
      #editComponent
    >
      <div class="input-group">
        <mat-form-field>
          <mat-label>ID</mat-label>
          <input matInput formControlName="oldShipId" type="number" required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Flag</mat-label>
          <input matInput formControlName="flag" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>IMO number</mat-label>
          <input matInput formControlName="imoNumber" type="number" required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>MMSI</mat-label>
          <input matInput formControlName="mmsi" type="number" required />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Ship name</mat-label>
          <input matInput formControlName="name" required />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Company name</mat-label>
          <input matInput formControlName="companyName" />
        </mat-form-field>
      </div>

      <div class="input-group">
        <mat-form-field>
          <mat-label>Start date</mat-label>
          <input matInput formControlName="startDate" type="date" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>End date</mat-label>
          <input matInput formControlName="endDate" type="date" />
        </mat-form-field>
      </div>
    </app-edit>
  </mat-card-content>
</mat-card>
