import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { BreadcrumbsService } from '@suvo-bi-core';
import { IShip } from '../interface/ship.interface';
import { ShipsService } from '../service/ships.service';

@Injectable()
export class ShipResolver implements Resolve<IShip> {
  constructor(
    private readonly ship: ShipsService,
    private readonly breadcrumb: BreadcrumbsService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<IShip> {
    const ship = await this.ship.getOne('', route.params['id']);
    this.breadcrumb.setDynamicNameSwap('ship', ship.name);
    return ship;
  }
}
