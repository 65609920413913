import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShipResolver } from './resolver/ship.resolver';
import { ShipEditScreenComponent } from './screens/edit/ship-edit-screen.component';
import { ShipTableScreenComponent } from './screens/table/ship-table-screen.component';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Ships' },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ShipTableScreenComponent,
      },
      {
        path: 'create',
        pathMatch: 'full',
        data: { breadcrumb: 'Create' },
        component: ShipEditScreenComponent,
      },
      {
        path: ':id',
        data: { breadcrumbDynamic: 'ship' },
        resolve: { data: ShipResolver },
        component: ShipEditScreenComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShipRoutingModule {}
