<table
  mat-table
  [dataSource]="dataSource$"
  matSort
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="listDropped($event)"
>
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let item">{{ item.name }}</td>
  </ng-container>

  <ng-container matColumnDef="imoNumber">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>IMO number</th>
    <td mat-cell *matCellDef="let item">IMO {{ item.imoNumber }}</td>
  </ng-container>

  <ng-container matColumnDef="flag">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Flag</th>
    <td mat-cell *matCellDef="let item">{{ item.flag }}</td>
  </ng-container>

  <ng-container matColumnDef="companyName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Company name</th>
    <td mat-cell *matCellDef="let item">{{ item.companyName }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headings"></tr>
  <tr
    mat-row
    class="row clickable"
    [class.hidden]="isLoading"
    *matRowDef="let row; columns: headings"
    [routerLink]="row._id"
  ></tr>

  <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
    <td class="mat-cell" [attr.colspan]="headings.length">
      <span>No matching data.</span>
    </td>
  </tr>
</table>

<ng-container *ngIf="isLoading">
  <suvo-bi-skeleton-table-rows
    [rowCount]="pageSize"
    [columnWidths]="[11, 11, 11, 11, 11, 11, 11, 11, 12]"
  ></suvo-bi-skeleton-table-rows>
</ng-container>

<mat-paginator
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
></mat-paginator>
