<mat-sidenav-container autosize>
  <mat-sidenav
    #filtersSidenav
    class="right-sidenav"
    [mode]="'side'"
    autoFocus="false"
    fixedInViewport="true"
    position="end"
    [opened]="filtersSidenavOpenedByDefault"
    [mode]="filtersSidenavMode"
  >
    <suvo-bi-filters-sidenav [filtersChannel]="filtersChannel" />
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header>
      @if (filtersChannel) {
        <suvo-bi-active-filters-widget [filtersChannel]="filtersChannel" />
      }
    </app-header>

    <mat-card style="margin: 0 var(--global-padding)">
      <mat-card-header class="between">
        <mat-card-title>Ship List</mat-card-title>
        <button mat-flat-button routerLink="create" [checkPermission]="['ship', 'edit']">
          Create
        </button>
      </mat-card-header>
      <mat-card-content>
        <app-ships-table tableAlias="ships" [filtersChannel]="filtersChannel" />
      </mat-card-content>
    </mat-card>
  </mat-sidenav-content>
</mat-sidenav-container>
