import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersChannel, FiltersChannelsService, FiltersSidenavService } from '@suvo-bi-lib';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ship-table-screen',
  templateUrl: './ship-table-screen.component.html',
  styleUrls: ['./ship-table-screen.component.scss'],
})
export class ShipTableScreenComponent {
  filtersChannel: FiltersChannel;

  filtersSidenavOpenedByDefault = false;
  filtersSidenavMode = 'side';

  unsubscribe$ = new Subject<void>();

  @ViewChild('filtersSidenav') filtersSidenav: MatSidenav;

  constructor(
    private readonly filtersChannelService: FiltersChannelsService,
    private breakpointObserver: BreakpointObserver,
    private filtersSidenavService: FiltersSidenavService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.filtersChannel = this.filtersChannelService.getFiltersChannel(
      'ships',
      null,
      null,
      'GENERAL',
    );

    if (
      this.breakpointObserver.isMatched([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
    ) {
      this.filtersSidenavOpenedByDefault = false;
      this.filtersSidenavService.setActive(false, false);
    } else {
      this.filtersSidenavOpenedByDefault = true;
      this.filtersSidenavService.setActive(true, true);
    }

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((result) => {
        if (result.matches) {
          this.filtersSidenavMode = 'over';
        } else {
          this.filtersSidenavMode = 'side';
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ngAfterViewInit() {
    this.filtersSidenavService.sidenavSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: any) => {
        setTimeout(() => {
          if (this.filtersSidenav) {
            state.open ? this.filtersSidenav.open() : this.filtersSidenav.close();
          }
        });
      });

    if (this.filtersSidenav) {
      this.filtersSidenav.openedChange
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((filtersSidenavOpen) => {
          setTimeout(() => {
            if (this.filtersSidenavService?.sidenavOpen != filtersSidenavOpen) {
              this.filtersSidenavService?.setOpen(filtersSidenavOpen);
            }
          });
        });
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
